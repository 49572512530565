import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { reduxActions, useDispatch } from 'store';
import { IDynamicUiField, IDictionaryValue } from 'plugins/api/types';
import { Select, FormElement } from 'components/controls';

// typ danych wejściowych
interface IProps {
  field: IDynamicUiField;
  pageSymbol: string;
  componentSymbol: string;
}

const DynamicSelect: FC<IProps> = ({ field, pageSymbol, componentSymbol }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // ustawianie wartości
  const [value, setValue] = useState<string | undefined>(field.value as string);

  const onChangeHandler = (item: IDictionaryValue) => {
    dispatch(
      reduxActions.setFieldValue({
        pageSymbol,
        componentSymbol,
        fieldName: field.field_symbol,
        fieldValue: item?.value
      })
    );

    setValue(item.value?.toString());
  };

  const options = field.dictionary?.values?.map((value) => ({
    value: value.value?.toString() || '',
    label: value.text,
    item: value
  }));

  return (
    <div className="dynamicSelect">
      <FormElement>
        <label>{field.label}</label>
        <Select
          value={value?.toString()}
          options={[
            ...(field.nullable
              ? [
                  {
                    value: '',
                    label: `${t('Wybierz')}`,
                    item: { value: null, text: `${t('Wybierz')}` }
                  }
                ]
              : []),
            ...options
          ]}
          onChange={(item) => item && onChangeHandler(item)}
        />
      </FormElement>
    </div>
  );
};

export default DynamicSelect;
